import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
import { ElementType } from '../../../types';
import { Option } from '../AddForm';
import { Element } from '../AddForm';
import EditFillWithUserInfoSettings from './Components/EditFillWithUserInfoSettings';
import ClearIcon from '@material-ui/icons/Clear';

export const getElementSettings: any = (elementType: ElementType) => {
  switch (elementType) {
    case ElementType.Text:
      return [
        {
          label: 'Oletusarvo',
          settingKey: 'defaultValue',
          settingType: 'textfield'
        },
        {
          label: 'Nimi Mirassa',
          settingKey: 'miraName',
          settingType: 'dropdown',
          showClear: true,
          settingOptions: [
            {
              label: 'Yksilönumero',
              value: 'InventEquipmentId'
            },
            {
              label: 'Tuotteen nimi',
              value: 'LRTInventSerialName'
            },
            {
              label: 'Teksti',
              value: 'Description'
            },
            {
              label: 'Nimiketunnus',
              value: 'ItemId'
            },
            {
              label: 'Käyttöomaisuuserän numero',
              value: 'LRTInventSerialAssetId'
            },
            {
              label: 'Tuotteen tila',
              value: 'LogisticStatusId'
            },
            {
              label: 'Ostolaskusta johtuva hankintapäivä',
              value: 'LRTAcquisitionDate'
            },
            {
              label: 'Hydrauliöljyn tyyppi',
              value: 'AESRentalHydraulicOilTypeId'
            },
            {
              label: 'Valmistajan sarjanumero',
              value: 'ManufactureInventSerialId'
            },
            {
              label: 'Valmistuspäivämäärä',
              value: 'ProdDate'
            },
            {
              label: 'Myyntitilaus',
              value: 'RefIdReceipt'
            },
            {
              label: 'Asiakkaan nimi',
              value: 'SalesOrderName'
            },
            {
              label: 'Vastuullinen TP',
              value: 'ResponsibleInventSiteId'
            },
            {
              label: 'Palautuspäivämäärä',
              value: 'RedeliveryDateTime'
            }
          ]
        }
      ];
    case ElementType.ProductId:
      return [
        {
          label: 'Oletusarvo',
          settingKey: 'defaultValue',
          settingType: 'textfield'
        },
        {
          label: 'Tuotetiedon haku Mirasta',
          settingKey: 'miraSearchEnabled',
          settingType: 'checkbox'
        },
        {
          label: 'Hae nykyinen vuokratieto',
          settingKey: 'currentlyRented',
          settingType: 'checkbox'
        }
      ];
    case ElementType.Header:
      return [
        {
          label: 'Tekstin koko',
          settingKey: 'fontSize',
          defaultValue: 'body1',
          settingOptions: [
            { label: 'body1', value: 'body1' },
            { label: 'h6', value: 'h6' },
            { label: 'h5', value: 'h5' },
            { label: 'h4', value: 'h4' },
            { label: 'h3', value: 'h3' },
            { label: 'h2', value: 'h2' }
          ],
          settingType: 'dropdown'
        },
        {
          label: 'Muuta otsikko hyperlinkiksi',
          settingKey: 'hyperlinkEnabled',
          settingType: 'checkbox'
        },
        {
          label: 'Hyperlinkin osoite',
          settingKey: 'hyperlinkValue',
          settingType: 'textfield',
          marginLeft: 16
        }
      ];
    case ElementType.Radio:
      return [
        {
          label: 'Lisää kommenttikenttä',
          settingKey: 'enableComments',
          settingType: 'checkbox'
        },
        {
          label: 'Kommenttikentän otsikko',
          settingKey: 'commentLabel',
          settingType: 'textfield'
        },
        {
          label: 'Sähköpostin lähetys päällä',
          settingKey: 'isEmailTriggerEnabled',
          settingType: 'checkbox'
        },
        {
          label: 'Jos vastaus on',
          settingKey: 'emailTriggerValue',
          settingType: 'dropdown',
          marginLeft: 16,
          showClear: true
        },
        {
          label: 'Lähetä lomake osotteisiin',
          settingKey: 'emailTo',
          settingType: 'textfield',
          marginLeft: 16
        }
      ];
    case ElementType.Checkbox:
      return [
        {
          label: 'Lisää kommenttikenttä',
          settingKey: 'enableComments',
          settingType: 'checkbox'
        },
        {
          label: 'Kommenttikentän otsikko',
          settingKey: 'commentLabel',
          settingType: 'textfield'
        }
      ];
    case ElementType.Number:
      return [
        {
          label: 'Oletusarvo',
          settingKey: 'defaultValue',
          settingType: 'textfield'
        },
        {
          label: 'Salli desimaalit',
          settingKey: 'allowDecimals',
          settingType: 'checkbox'
        },
        {
          label: 'Lisää kommenttikenttä',
          settingKey: 'enableComments',
          settingType: 'checkbox'
        },
        {
          label: 'Kommenttikentän otsikko',
          settingKey: 'commentLabel',
          settingType: 'textfield'
        }
      ];
    case ElementType.Dropdown:
      return [
        {
          label: 'Lisää kommenttikenttä',
          settingKey: 'enableComments',
          settingType: 'checkbox'
        },
        {
          label: 'Kommenttikentän otsikko',
          settingKey: 'commentLabel',
          settingType: 'textfield'
        },
        {
          label: 'Sähköpostin lähetys päällä',
          settingKey: 'isEmailTriggerEnabled',
          settingType: 'checkbox'
        },
        {
          label: 'Jos vastaus on',
          settingKey: 'emailTriggerValue',
          settingType: 'dropdown',
          marginLeft: 16,
          showClear: true
        },
        {
          label: 'Lähetä lomake osotteisiin',
          settingKey: 'emailTo',
          settingType: 'textfield',
          marginLeft: 16
        }
      ];
    case ElementType.File:
      return [
        {
          label: 'Lisää kommenttikenttä',
          settingKey: 'enableComments',
          settingType: 'checkbox'
        },
        {
          label: 'Kommenttikentän otsikko',
          settingKey: 'commentLabel',
          settingType: 'textfield'
        }
      ];
    case ElementType.Email:
      return [
        {
          label: 'Oletusarvo',
          settingKey: 'defaultValue',
          settingType: 'textfield'
        }
      ];
    case ElementType.Textarea:
      return [
        {
          label: 'Oletusarvo',
          settingKey: 'defaultValue',
          settingType: 'textfield'
        }
      ];
    case ElementType.LinkedList:
      return [
        {
          label: 'Lähetä sähköposti',
          settingKey: 'sendEmail',
          defaultValue: false,
          settingOptions: [
            { label: 'Kyllä', value: true },
            { label: 'Ei', value: false }
          ],
          settingType: 'dropdown'
        }
      ];
    case ElementType.UserInfo:
      return [
        {
          label: 'Käyttäjän tiedot',
          settingType: 'userinfo',
          settingKey: 'userInfo'
        }
      ];
    default:
      return [];
  }
};

interface EditElementSettingsProps {
  elementType: ElementType;
  onSetElementProp: (propKey: string, value: string | boolean | any[]) => void;
  props: any;
  options?: Option[];
  allElements: Element[];
}

const renderSettings = (
  settings: any,
  onSetElementProp: (propKey: string, value: string | boolean | any[]) => void,
  props: any,
  index: number,
  allElements: Element[],
  options?: Option[]
) => {
  const { settingType, settingKey, label, settingOptions, defaultValue } =
    settings;
  if (settingType === 'checkbox') {
    return (
      <FormControlLabel
        key={label + index}
        control={
          <Checkbox
            color="primary"
            name={settingKey}
            checked={props[settingKey] ?? false}
            onChange={(e) => {
              if (e.target.checked) {
                onSetElementProp(settingKey, true);
              } else {
                onSetElementProp(settingKey, false);
              }
            }}
          />
        }
        label={label}
      />
    );
  } else if (settingType === 'textfield') {
    return (
      <FormControlLabel
        key={label + index}
        style={{ marginLeft: settings.marginLeft || 0 }}
        labelPlacement="start"
        control={
          <TextField
            value={props[settingKey] ?? ''}
            onChange={(e) => onSetElementProp(settingKey, e.target.value)}
            style={{ marginLeft: 20 }}
          />
        }
        label={label}
      />
    );
  } else if (settingType === 'dropdown') {
    const dropdownOptions = settingOptions || options;
    return (
      <Grid container>
        <FormControlLabel
          key={label + index}
          labelPlacement="start"
          style={{ marginLeft: settings.marginLeft || 0 }}
          control={
            <Select
              style={{ width: 150, marginLeft: 20 }}
              onChange={(e) =>
                onSetElementProp(settingKey, e.target.value as any)
              }
              value={props[settingKey] ?? defaultValue ?? ''}
            >
              {dropdownOptions?.map((option: any, i: number) => (
                <MenuItem color="primary" value={option.value} key={i + label}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          }
          label={label}
        />
        {settings.showClear && (
          <>
            <IconButton
              onClick={() => onSetElementProp(settingKey, defaultValue)}
              size="small"
              style={{ marginLeft: 16 }}
            >
              <ClearIcon />
            </IconButton>
          </>
        )}
      </Grid>
    );
  } else if (settingType === 'userinfo') {
    const hasUserInfoProps = props['userInfo'];
    const userInfoFields = hasUserInfoProps ? props['userInfo'] : [];
    return (
      <EditFillWithUserInfoSettings
        key={label + index}
        userInfoFields={userInfoFields}
        onChange={onSetElementProp}
        allElements={allElements}
      />
    );
  } else {
    return (
      <Typography key={label + index}>
        Unsupported setting type: {settingType}
      </Typography>
    );
  }
};

const EditElementSettings = ({
  elementType,
  onSetElementProp,
  props,
  options,
  allElements
}: EditElementSettingsProps) => {
  const elementSettings = getElementSettings(elementType) as any;
  return (
    <>
      {elementSettings?.map((settings: any, index: number) => {
        return renderSettings(
          settings,
          onSetElementProp,
          props,
          index,
          allElements,
          options
        );
      })}
    </>
  );
};

export default EditElementSettings;
